import React from "react";

const IntroOverlay = () => {
  return (
    <>
      <div className='intro-overlay'>
      </div>
    </>
  );
};

export default IntroOverlay;
