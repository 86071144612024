import React from "react";

import image1 from "../assets/Ui-Design.jpg";
import image2 from "../assets/branding.jpg";
import image3 from "../assets/front-end.jpg";

const caseStudies = [
  {
    id: 1,
    subtitle: "Ui-Ux Design",
    title: "Understanding your target users is as much important as marketing for upsale. It's also about aesthetics, I believe your website is identity of your company. So let's make it unique!",
    img: image3
  },
  {
    id: 2,
    subtitle: "Branding Strategy",
    title: "Idea is to identify your brand by developing corporate identity, user guidelines, branding mockups and many more products. In short anything that takes to get your brand in notice!",
    img: image2
  },
  {
    id: 3,
    subtitle: "Front-End Development",
    title: "A good website required multiple stages of target audience research, observations and propotype testing, to understand the target audience needs. A product perfectly suited to your users!",
    img: image1
  }
];


const Slider = () => {
  return (
    <section className='cases'>
      <div className='container'>
        <div className='row'>
          {caseStudies.map(caseItem => (
            <div className='case' key={caseItem.id}>
              <div className='case-image'>
                <img
                  src={caseItem.img}
                  alt={caseItem.title} 
                  data-scroll data-scroll-speed="2" data-scroll-position="top" data-scroll-direction="vertical"
                />
              </div>
              <div className='case-details' data-scroll data-scroll-speed="2" data-scroll-position="top" data-scroll-direction="vertical">
                <span >{caseItem.subtitle}</span>
                <h2>{caseItem.title}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Slider;