import React from "react";

const Banner = () => {
  return (
    <section className='main'>
      <div className='container'>
        <div className='row'>
          <h2>
            <div className='line' data-scroll data-scroll-speed="2" data-scroll-position="top" data-scroll-direction="vertical">
              <span> Design. Develop. Deploy.</span>
            </div>
          </h2>
          <div className='paragraph' data-scroll data-scroll-speed="2" data-scroll-position="top" data-scroll-direction="horizontal">
            <p>A Software Engineer working in Aerospace Industry. Who imagines, designs and develops unique brand identities and tailor-made digital solutions for cultural and corporate projects from
              scratch. I am also a strategic design and development freelancer with an uncompromised commitment to quality and the generation of true value.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
