import React, { useEffect } from "react";
import { gsap } from "gsap";
import locomotiveScroll from "locomotive-scroll";

import "./styles/App.scss";

import Cursor from "./components/cursor";
import Header from "./components/header";
import Home from "./pages/home";

function App() {
  useEffect(() => {
    gsap.to("body", 0, { css: { visibility: "visible" } });
  });

  const scrollRef = React.createRef();

  useEffect(() => {
    new locomotiveScroll({
      el: scrollRef.current,
      smooth: true
    });
  });

  return (
    <>
      <Cursor />
      <Header />
      <div className='App' ref={scrollRef}>
        <Home />
      </div>
    </>
  );
}

export default App;
