import React from "react";

const eachSkill = ['Ui-Ux Design.', 'Full-Stack Engineering.', 'Branding Design.', 'Project Marketing.'];

const eachSkillLineObject = [];
for (const [index, value] of eachSkill.entries()) {
    eachSkillLineObject.push(
        <h2 className='each-line-skill' key={index} data-scroll data-scroll-speed={index+1} data-scroll-position="top" data-scroll-direction="horizontal">
                {value} {value} <span>{value}</span> {value} {value}
        </h2>
    );
}

const Skills = () => {
    return (
        <section className='skills'>
            <div className='row'>
                {eachSkillLineObject}
            </div>
        </section>
    );
};

export default Skills;
